.custom-form {
    max-width: 1000px;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  
  .full-width-message {
    margin-top: 1rem;
  }
  
  .form-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .submit-button {
    background-color: #535895;
    color: white;
    padding: 1rem;
    font-weight: 500;
    font-size: 1rem;
    width: 80%;
    height: 50px;
    text-transform: none;
    width: 100%;
    border-radius:0;
  }

/* MOBILE OVERRIDE */
@media (max-width: 768px) {
    .form-grid {
      grid-template-columns: 1fr;
    }
  
    .form-options {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .submit-button {
      width: 100%;
    }
  }  