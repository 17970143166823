.quote-review-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: 'Inter', sans-serif;
  background-color: #fefaf7;
}

.quote-review-container {
  position: relative;
  max-width: 700px;
  width: 100%;
  text-align: center;
}

.quote-review-paragraph {
  text-align: left;
  margin: 1rem 0 2rem;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.quote-review-content {
  min-height: 280px;
}

.quote-review-text {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #222;
  text-align: left;
  margin-bottom: 2rem;
  min-height: 100px;
}

.quote-review-info-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
}

.quote-review-logo {
  max-width: 100px;
  max-height: 80px;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 6px;
}

.quote-review-info {
  text-align: left;
}

.quote-review-name {
  font-weight: bold;
  font-size: 1.1rem;
}

.quote-review-title {
  font-style: italic;
  color: #555;
  font-size: 0.95rem;
}

.quote-review-stars {
  color: #f5a623;
  font-size: 1.2rem;
  margin-top: 0.25rem;
}

.quote-review-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 1.5rem;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-review-arrow-left {
  left: -24px;
}

.quote-review-arrow-right {
  right: -24px;
}

.quote-review-arrow:hover {
  background-color: #f3f3f3;
}

@media (max-width: 768px) {
  .quote-review-info-logo {
    flex-direction: column;
  }

  .quote-review-info {
    text-align: center;
  }

  .quote-review-arrow-left,
  .quote-review-arrow-right {
    top: auto;
    bottom: -3rem;
    transform: none;
  }

  .quote-review-arrow-left {
    left: 30%;
  }

  .quote-review-arrow-right {
    right: 30%;
  }
}
