


.omrade-our-services-section {
  background: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.omrade-our-services-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: left;
}

.omrade-our-services-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px; /* spacing between cards */
  max-width: 1200px;
  width: 100%;
}

.omrade-our-services-card {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2px solid black; /* svart linje i botten */

}

.omrade-our-services-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
}

  .omrade-our-services-icon {
    font-size: 32px;
    filter: grayscale(100%)brightness(70%); /* gråskala och mörkare */;

  
}

.omrade-our-services-card-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  position: relative;
}

.omrade-our-services-card-title::after {
  content: '';
  display: block;
  width: 60px;
  margin-top: 4px;
}

.omrade-our-services-card ul {
  list-style: none;
  padding: 0;
  line-height: 32px;
  margin-top: 12px;
}

.omrade-our-services-card li {
  font-size: 15px;
  margin-bottom: 6px;
  text-align: left;
}

/* 📱 Mobilanpassning */
@media (max-width: 768px) {
  .omrade-our-services-grid {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .omrade-our-services-card {
    width: 100%;
    max-width: 300px;
    align-items: flex-start;
  }

  .omrade-our-services-icon {
    font-size: 28px;
  }

  .omrade-our-services-title {
    font-size: 28px;
  }
}


/* Mobilanpassning */
@media (max-width: 600px) {
  .omrade-our-services-title {
    font-size: 28px;
  }

  .omrade-our-services-grid {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .omrade-our-services-icon {
    font-size: 28px;
  }

  .omrade-our-services-card-title {
    font-size: 18px;
  }
}

.omrade-herov5-section {
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 20px 100px;

}

.omrade-herov5-button-secondary {
  all: unset; /* ✅ Removes all default browser styles */

  background: none;
  color: white;
  padding: 12px 30px;

  position: relative;
  text-decoration: none; /* ✅ removes default underline */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
  transition: color 0.3s ease;
}
.omrade-herov5-button-secondary:hover {
  text-decoration: none; /* ✅ removes default underline */
}


.omrade-herov5-button-secondary::after {
  content: "";
  position: absolute;
  bottom: 6px; /* adjust to align nicely with font */
  left: 30px;  /* same as padding-left */
  width: 0;
  height: 2px;
  background: #f77e22; /* ✅ underline is orange */
  transition: width 0.3s ease;
}

.omrade-herov5-button-secondary:hover::after {
  
  width: calc(100% - 60px); /* full width minus left + right padding */
}

.omrade-herov5-buttons{
  display: flex;
  gap: 20px; /* ✅ space between buttons */
}

.omrade-herov5-overlay {
  max-width: 700px;
  margin-top: 40px;
  margin-left: 40px;
  text-align: left;
}

.omrade-herov5-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 1); /* ✅ subtle glow for legibility */
}

.omrade-herov5-paragraph {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 1); /* ✅ subtle glow for legibility */
}

.omrade-herov5-button {
  background-color: #1a76c2;
  color: white;
  padding: 12px 30px;
  border-radius: 0;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  transition: background 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* ✅ box shadow for pop */
}

.omrade-herov5-button:hover {
  background: #f77e22;
  color: white;
}

/* ✅ Mobile adjustments */
@media (max-width: 768px) {
  .omrade-herov5-section {
    padding: 120px 16px 60px;
  }

  .omrade-herov5-overlay {
    margin-left: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .omrade-herov5-title {
    font-size: 34px;
  }

  .omrade-herov5-paragraph {
    font-size: 16px;
    line-height: 1.6;
  }

  .omrade-herov5-button {
    padding: 10px 24px;
    font-size: 16px;
  }
}


.omrade-content-h2{
padding-bottom:10px;  border-bottom:1px solid black; margin: auto;
}
.omrade-content-container{
display:flex; width:95%;max-width:1200px; justify-content:space-evenly; align-items:start;  gap:50px; margin:50px auto; margin-bottom:0}

.omrade-content-h2-container{
    flex:1; margin-bottom:50px;
}
.omrade-content-h2-sibling{
    flex:2
}
.omrade-alla-tjanster{
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
}
.omrade-alla-tjanster-text-col{
  margin: 0 auto;
  max-width: 800px;
}
.omrade-alla-tjanster-item-container{
  display: flex;
  margin-top: 5px;
  width: 99%;
  margin: 0 auto;
  max-width: 1200px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.omrade-alla-tjanster-item{
  background-color: #d1e3f2
  ;
  padding:8px 12px;
  font-weight: 400;

  margin: 2px;
  font-size: 18px;
}


.omrade-fokusv2{
   display: grid;
   grid-template-columns: 1fr 1fr; /* Two columns */
   grid-template-rows: 1fr 1fr;    /* Two rows */
   gap: 10px; /* optional spacing between grid items */
 }
.omrade-fokusv2-item{
    background-color: #1A76C2;
    color: white;
}
.omrade-herov2-container{
        display: flex;
        justify-content: center; /* Adjust spacing between left and right sections */
        align-items: center; /* Align items to the top */
        padding: 40px;
        margin-top: 50px;
        height: 500px;
        background-position: center 25%;

        background-color: #EAF1F7;
        position: relative; /* Ensures proper stacking context */
      
}


/* expert-accordion block styles */
.omrade-info-expert-accordion {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    max-width: 800px;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .omrade-expert-accordion-left {
    flex: 1;
  }
  .omrade-expert-accordion-left p {
  text-align: left;}
  .omrade-expert-accordion-right {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .omrade-expert-accordion-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }









  .omrade-advantages-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
  
  .omrade-advantage-card {
    flex: 1 1 calc(24% - 16px); /* 3 columns on desktop */
    min-width: 200px;
    padding: 16px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
  }
  
  .omrade-advantage-heading {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
  
  .omrade-advantage-paragraph {
    font-size: 1rem;
    color: #666;
  }
  

  







/* Background Image Layer (with Filter) */


/* Text & Content Container */
.avtal-herov3-left {
  position: relative; /* Keeps text above background */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex; /* Centers content */
  align-items: center; /* Centers text and buttons */
  text-align: center; /* Ensures text stays centered */
  z-index: 1;
  margin-bottom: 20px;
  max-width: 600px; /* Prevents text from stretching too wide */
  padding: 0 20px; /* Adds spacing on smaller screens */
}

.omrade-herov3-subcontainer{
  z-index: 1;
  color: white;
  width: 100%;
  max-width: 700px;
  padding-top: 275px;
  min-height: 520px;
  text-align: center;

}
.omrade-main-herov3-p-no-shadow{
}
.omrade-main-herov3-p{
  margin:0;
  min-height: 50px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Adds a shadow behind text */

}
.omrade-herov3-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;    object-fit: cover; /* ✅ Prevents distortion */

  background-position: center;
  filter: brightness(75%) contrast(85%);
  background-repeat: no-repeat;
  
  background-color: #1A76C2;
}
.omrade-cta-container{
  display: flex;
  flex-direction: column;
  min-height: 92px;
  align-items: center;
  width:100%;
}
.omrade-form-container{
  display: flex;
  justify-content: center;
  margin: 50px 0;}
/* Main Container */
.omrade-herov3-container {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin-top: 0;
  height: 550px;
  background-position: center 25%;
  background-color: #EAF1F7;
  position: relative;
  text-align: center;
}
.omrade-herov4-container {
  display: flex;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin-top: 0;
  height: 600px;
  background-position: center 25%;
  background-color: #EAF1F7;
  position: relative;
  text-align: center;
}
.omrade-alla-tjanster-123{
background: #f2f0ef; width: 100%; padding: 30px 0;}
/* CTA Button Row */
.omrade-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 15px; /* Adds space between buttons */
  margin-bottom: 10px;
}
.omrade-offer-main{
  flex: 1;
}
.omrade-offer-sub-h2{margin: 0;}
.omrade-offer-sub-p{margin-bottom: 0;}
.omrade-vemhur-block{
  display: flex;
                    flex-direction: column;
                    background-color:#f2f0ef;
                    padding:20px 20px;
}
.omrade-vemhur-sub{
  display: flex;
                flex: 1;
                flex-direction: column;
                gap: 10;
}
/* CTA Buttons */
.omrade-cta-button {
  all: unset;
  padding: 12px 24px;
  background-color: #1A76C2;
  color: white;
  margin: 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-align: center;
}

/* Hover effect */
.omrade-cta-button:hover {
  background-color: #ff9d33;
}

/* Mobile Adjustments */
@media screen and (max-width: 768px) {


  
  .avtal-herov3-left {
    margin-bottom: 10px;
    padding: 0 15px;
    max-width: 90%; /* Prevents text from stretching too wide */
  }

  /* Stack buttons on mobile */
  .omrade-row {
    flex-direction: row;

    gap: 10px; /* Adds space between stacked buttons */
    width: 100%;
  }

  .omrade-cta-button {
    padding:10px 16px
  }
}












.omrade-processen{
    text-align: left;
    display: flex;
    width: 90%;max-width: 1440px;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;

}
.omrade-anledningar-li:before{
    content: '\2713';
    display: inline-block;
    color: 
    #ff9d33
    ;
    font-size: 25px;
    padding:0px 6px 0 0;
}

  
.omrade-anledningar-li{
    list-style-type: none;
    display: flex;
   
    flex-direction: row;
  }

.center-text{text-align: center;}

.omrade-anledningar{
    display: flex;
    flex-direction: row;
    margin: 60px auto;
    width: 90%;
    gap: 20px;
    max-width: 1440px;
    justify-content: space-around;
}
.omrade-anledningar-subdiv{
    display: flex;
    max-width: 600px;
    flex-direction: column;
}
.omrade-processen-subcontainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
    margin: 0 auto;
    gap:10px
}
.omrade-anledningar-subdiv p{
margin: 0;
text-align: left;
}
.omrade-anledningar-heading{
    text-align: left;
    margin: 20px 0;

}
.omrade-anledningar-text{
    margin: 0;

}

.omrade-anledningar-ul{
    margin: 5px 0; 
padding: 0 10px;
display: flex;
flex-direction: row;
}
.vi-deltar{
    color:#1A76C2;
}
.omrade-anledningar-img{
    margin-top: 60px;
    height: 300px;
    margin: 60px 0 auto;
    width:400px;
    max-height: 300px;
    max-width:400px;
    min-height: 300px;
    min-width:400px;
}

.omrade-img{
    height: 70px;
    width:70px;
    display: block;

    margin: 0 auto;
}
.omrade-processen-subdiv{
    max-width:490px;
    flex: 1 1 calc(30.33% - 20px); /* Adjust so that 3 items fit within the row with gaps */
    min-width: 200px;
    padding: 10px;

    
}

  /* Tablet (2x3 grid) */
  @media (max-width: 1024px) {
    .omrade-advantage-card {
      flex: 1 1 calc(50% - 16px); /* 2 columns */
    }
    
  }
  
  /* Mobile (1x6 grid) */
  @media (max-width: 768px) {
    .omrade-advantage-card {
      flex: 1 1 100%; /* 1 column */
    }
  }
@media (max-width: 920px) {

    .omrade-processen-subcontainer{
        flex-direction: column;
    }
        
        .omrade-processen-subdiv{
            width:90%;
    }
    .omrade-anledningar-img{
        height: 300px;
        width:400px;
    
    }
    .omrade-anledningar{

        flex-direction: column-reverse;
    }
}

.omrade-flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.omrade-flex-item {
    flex: 1 1 100%;
    box-sizing: border-box;
    padding: 10px;
}

@media (min-width: 768px) {
    .omrade-flex-item {
        flex: 1 1 48%;
    }
}

@media (min-width: 1024px) {
    .omrade-flex-item {
        flex: 1 1 30%;
    }
}

.omrade-read-more-button {
    background: none;
    border: none;
    color: #1A76C2;
    cursor: pointer;
    justify-self: flex-end;
    align-self: flex-end;
    padding: 0;
    font-size: 16px; /* Adjust as needed */
    bottom: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
}

.omrade-read-more-button:hover {
    color: #ff9d33;
}


  
.tjansteItem-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: flex-start;
    align-content: center;
    gap: 10px;
    max-width: 1200px;
    width: 95%;
  }
  

   /* Arrow styling */
   .tjansteItem-arrow {
    align-self: center;
    font-size: 30px;
    color: #1A76C2; /* Blue arrow */
    transition: color 0.3s ease;
  }

    /* Text content */
    .tjansteItem-content {
        text-align: left;
        display: flex;
      }
        
  .tjansteItem-title {
    font-size: 16px;
    margin:0;
    font-weight: 500;
    justify-self: center;
  }

 
  /* Container for each grid item */
  .tjansteItem-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    gap: 10px;
  }
 
/* Link Styles */
.tjansteItem-link {
    display: flex; /* Set display to flex */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shading effect on hover */

    width: 175px; /* Set width */
    color: #333; /* Text main color */
    padding: 5px; /* Padding */
    border-radius: 2px; /* Rounded corners */
    position: relative; /* Positioning context */
    background: linear-gradient(to right, #1A76C2 50%, #f0f4f8 50%); /* Background gradient */
    background-size: 200% 100%; /* Background size */
    background-position: right bottom; /* Background position */
    transition: background-position 0.3s ease, background-color 0.3s ease; /* Transition effects */
}

  
  .tjansteItem-link:hover {
    background-position: left bottom;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Shading effect on hover */

    background-color: #1A76C2; /* Blue background on hover */
    color: white; /* Change text color to white on hover */
  }


  /* Hover state for text, title, and arrow */
  .tjansteItem-link:hover .tjansteItem-title,
  .tjansteItem-link:hover .tjansteItem-arrow {
    color: white; /* Ensure both the title and arrow turn white */
  }
  

  @media (max-width: 900px) {

  
    .tjansteItem-container {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .tjansteItem-cta-button:hover{
    background-color: white    ;
    border: 1px solid #ff9d33;
    color:#ff9d33;
    
  }
    

.tjansteItem-cta-grid {
    display: grid;
    margin: 0 auto;
    width: 95%;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between cards */
  }
 
  .omrade-vemhur-link {
    all: unset;
    font-size: 17px;
    
    color: #1A76C2;
    align-self: flex-end;
    justify-self: flex-end;
    padding: 8px 16px;
    transition: transform 0.3s ease-out,  color 0.3s ease-out;
}
.omrade-info-image-row{
  display: flex;
  gap: 20px;

  flex-direction: row;
}
.omrade-info-image-row.reverse{
  flex-direction: row-reverse;
  gap: 20px;

}

.omrade-info-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end; 
}

.omrade-info-image {
  width: 100%;
  height: auto;
  max-height: 650px;
  min-height: 250px;
  object-fit:scale-down;
}
.omrade-info-image-heading{
  margin: 0;
}
.omrade-info-image-paragraph{
  text-align: left;
  margin: 0;
  max-width: 800px;           /* Full width */

}
.omrade-ref-container{
  margin: 0 auto;
  max-width: 1200px;
  width: 98%;

}
.omrade-ref-container p {
  margin: 0 auto;
  max-width: 800px;

}

.omrade-info-text-container {
  display: flex;             /* Enable Flexbox */
  flex-direction: column;    /* Ensure content stacks vertically */
  justify-content: center;   /* Vertically center the content */
  align-items: flex-start;   /* Align text to the left (or use center if needed) */
  flex: 1;                   /* Allow the container to grow and fill the parent */
  width: 100%;    
  height: 100%;              /* Full height */
  
  box-sizing: border-box;    /* Ensure padding doesn't affect dimensions */
}

.omrade-vemhur-link:hover {
    text-decoration: none;
    color: #ff9d33;
    transform: translateX(20px) ;
    font-size: 17px; /* Optional: Increase font size */
}
.omrade-hero-stars-container{
  display: flex;
  flex-direction: column;
}
.omrade-hero-stars-link{

  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Adds a shadow behind text */

  color: white;
}
.omrade-hero-stars{
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Adds a shadow behind text */

  color: gold;
}
.omrade-cta-block{
  display:flex;flex:1; flex-direction:column;text-align:left; background-color:#f2f0ef; padding:20px 20px;
}
.omrade-offer-sub{
  display:flex;flex:1; flex-direction:row; gap:10px
}
.omrade-offer{
  margin: 0px auto;
  margin-bottom: 40px;
  text-align: center;
  gap:40px;
   display: flex;
   
   flex-direction: column;
   max-width: 1440px;
   width: 95%;
}


  .omrade-vemhur{
   margin: 40px auto;
   text-align: center;
   gap:40px;
    display: flex;
    
    flex-direction: row;
    max-width: 1440px;
    width: 95%;
  }
  .omrade-main-herov3-h2{
    font-size: 30px;
    margin-top: 5px;

  }
  .omrade-main-herov3-h1{
    font-size: 40px;
  }
  .tjansteItem-cta-card {
    
    background-color: #EAF1F7; /* Light blue */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    min-height: 150px; /* Ensure cards have the same height */
  }
  .omrade-info-med-bild {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 0;
  }
  
  .omrade-info-image {
    width: 100%;
    height: auto;
    max-height: 550px;
    max-width: 1000px;
    min-height: 250px;
    object-fit:cover;
  }
  
  .tjansteItem-cta-card p {
    margin: 0; /* Remove margin from paragraphs */
  }
  /* Media query for 2x2 grid on smaller screens */
  @media screen and (max-width: 1100px) {
    .omrade-vemhur{
      flex-direction: column;}
    
    .omrade-expert-accordion-heading{
      margin: 5px auto;
      text-align: left;
    }
    .omrade-info-expert-accordion{
      flex-direction: column;
      width:90%;
      margin:0 auto;
      gap:5px;
      }
    .omrade-info-text-container {
      flex-direction: column;
      width:90%;
      margin:0 auto;
      margin-top: 25px;
      gap:5px;
      }
      .omrade-info-image-row{
        flex-direction: column;

      }
      .omrade-info-image-row.reverse{
        flex-direction: column;

      }


      .omrade-offer-sub{
         flex-direction:column;
      }
  
      .omrade-herov3-subcontainer{
        padding-top: 30px;
        min-height: 250px;

    
      }
    }
    @media screen and (max-width: 400px) {
      .omrade-herov3-text{
        min-height: 160px;
      }
    }
    
    @media screen and (max-width: 800px) {
      .omrade-herov3-text{
        min-height: 100px;
      }
    
.omrade-expert-accordion-paragraph p,
.omrade-alla-tjanster-item-container h3,
.omrade-alla-tjanster-text-col p,
.omrade-info-image-paragraph p {
font-size: 18px;
margin-bottom: 10px;
}
}

@media screen and (max-width: 800px) {

.omrade-herov2-container {

  height:200px;

}}
@media screen and (max-width: 1200px) and (min-width: 800px) {
 
    .omrade-herov2-container {
    
      height: 250px;
    
    }
}
@media (max-width: 700px) {
   .omrade-main-herov3-h1{
    font-size: 24px;
   }
 
   .omrade-main-herov3-h2{
    font-size: 20px;
    margin-top: 5px;

  }  .omrade-main-herov3-p-no-shadow{
    line-height: 22px;
    font-size: 14px;
    min-height: 50px;
   }
   .omrade-main-herov3-p{
    min-height: 60px;
    line-height: 22px;
    font-size: 14px;
    
   }
   .omrade-vemhur{
    margin-top: 0;
  }

    .tjansteItem-cta-grid {
        display: grid;
        margin: 0 auto;
        width: 95%;
        max-width: 1200px;
        grid-template-columns: 1fr; /* Responsive grid */
        gap: 20px; /* Space between cards */
      }
      .omrade-herov4-container {
        padding: 0 20px ;
      }
      .omrade-alla-tjanster-item{

      padding: 6px 10px ;
      }
    }


